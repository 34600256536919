module.exports = {
  appId: '',
  requestAppId: '',
  getItem (key) {
    return localStorage.getItem(this.appId + '_' + key)
  },
  setItem (key, value) {
    localStorage.setItem(this.appId + '_' + key, value)
  },
  removeItem (key) {
    localStorage.removeItem(this.appId + '_' + key)
  },
}



