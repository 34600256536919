import axios from "axios"
import localStorageManager from '../localStorageManager'

const setUserInfo = "setUserInfo"
const clearUserInfo = "clearUserInfo"
const setLogin = "setLogin"

// const URL_Signup = "api/account/Register"
const URL_Login = "adminToken"
const URL_ChangePassword = "api/admin/account/ChangePassword"
const URL_Logout = ""

function initialState() {
  return {
    userInfo: {},
    isLogin: false,
  }
}

export default {
  namespaced: true,

  state: initialState,

  actions: {
    // async signup({
    //   commit
    // }, data) {
    //   return axios.post(URL_Signup, data).then(res => {
    //     console.log(res)
    //   }).catch((error) => {
    //     console.log(error)
    //     throw error
    //   })
    // },
    async login({
      commit
    }, data) {
      return axios.post(URL_Login, 'username=' + data.username + '&password=' + data.password, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then(res => {
        let userInfo = {
          id: res.data.instructor.userId,
          username: data.username,
          instructorId: res.data.instructor.instructorId,
          instructorName: res.data.instructor.instructorName,
        }
        commit(setUserInfo, userInfo)
        commit(setLogin, res.data)
        console.log('Login results:', res)
        localStorageManager.setItem('logintime', new Date().getTime())
        localStorageManager.setItem('lastLogintime', new Date().getTime())
        localStorageManager.setItem('userInfo', JSON.stringify(userInfo))
      }).catch((error) => {
        console.error(error.response.data)
        throw error
      })
    },
    async changePassword(_, data) {
      return axios.post(URL_ChangePassword, data)
      .then(res => {
        return res.data
      })
      .catch(err => {
        throw err
      })
    },
    async logout({
      commit
    }, data) {
      commit(clearUserInfo)
    },
  },

  mutations: {
    [setUserInfo](state, data) {
      state.userInfo = {
        id: data.id,
        username: data.username,
        instructorId: data.instructorId,
        instructorName: data.instructorName,
      }
    },
    [clearUserInfo](state) {
      state.userInfo = {}
      state.isLogin = false
      localStorageManager.removeItem('access_token')
      localStorageManager.removeItem('expires_in')
      localStorageManager.removeItem('logintime')
      localStorageManager.removeItem('lastLogintime')
      localStorageManager.removeItem('userInfo')
    },
    [setLogin](state, data) {
      state.isLogin = true
      localStorageManager.setItem('access_token', data.access_token)
      localStorageManager.setItem('expires_in', data.expires_in)
    },
  },

  getters: {
    userInfo: state => state.userInfo,
    isLogin: state => state.isLogin,
  }
}