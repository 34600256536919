import axios from "axios"

const setFeedback = "setFeedback"
const addFeedback = "addFeedback"
const editFeedback = "editFeedback"

const setPerformanceTypeList = "setPerformanceTypeList"

const URL_getFeedback = "/api/admin/Assessment/GetAssessmentByLesson/"
const URL_postAddFeedback = "/api/admin/Assessment/AddAssessment"
const URL_postUpdateFeedback = "/api/admin/Assessment/UpdateAssessment"

const URL_getPerformanceTypeList = "/api/admin/PerformanceType/GetList"

function initialState() {
  return {
    currentFeedback: [],
    performanceTypeList: []
  }
}

export default {
  namespaced: true,

  state: initialState,

  actions: {
    async getFeedback({
      commit
    }, data) {
      return axios.get(`${URL_getFeedback}${data.instructorId}/${data.lessonId}`).then(res => {
        commit(setFeedback, res.data)
      }).catch((error) => {
        console.log(error)
        throw error
      })
    },
    async postFeedback({
      commit
    }, data) {
      return axios.post(data.isEdit ? URL_postUpdateFeedback : URL_postAddFeedback, data).then(res => {
        data.isEdit ? commit(editFeedback, res.data) : commit(addFeedback, res.data)
      }).catch((error) => {
        console.log(error)
        throw error
      })
    },

    async getPerformanceTypeList({
      commit
    }, data) {
      return axios.get(`${URL_getPerformanceTypeList}`).then(res => {
        commit(setPerformanceTypeList, res.data)
      }).catch((error) => {
        console.log(error)
        throw error
      })
    },
  },

  mutations: {
    [setFeedback](state, data) {
      state.currentFeedback = data
    },
    [addFeedback](state, data) {
      state.currentFeedback.push(data)
    },
    [editFeedback](state, data) {
      let currentFeedback = JSON.parse(JSON.stringify(state.currentFeedback))
      let index = currentFeedback.findIndex(x => x.id == data.id)
      currentFeedback.splice(index, 1, data)
      state.currentFeedback = currentFeedback
    },

    [setPerformanceTypeList](state, data) {
      state.performanceTypeList = data
    },
  },

  getters: {
    currentFeedback: state => state.currentFeedback,
    performanceTypeList: state => state.performanceTypeList,
  }
}