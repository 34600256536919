<template>
  <div id="app">
    <el-container style="height:100%">
      <el-header class="header" v-if="isLogin">
        <el-menu background-color="rgb(102, 102, 102)" mode="horizontal" style="height:60px;">
          <div class="more">
            <el-button type="info" icon="el-icon-s-fold" circle @click="isCollapse = !isCollapse"></el-button>
          </div>
          <el-image :src="require('./assets/logo_word.png')" fit="fill" @click="backHome()"></el-image>&nbsp;
          <el-image class="logo" :src="require('./assets/logo_pic.png')" fit="fill" @click="backHome()"></el-image>
          <el-dropdown split-button class="user" @command="clickDropdown">
            <i class="el-icon-user"></i>{{ userInfo.username }}
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="changePassword">
                <i class="el-icon-setting"></i>修改密码
              </el-dropdown-item>
              <el-dropdown-item command="logout">
                <i class="el-icon-close"></i>退出登录
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu>
      </el-header>
      <el-aside style="background-color: rgba(102, 102, 102)" v-show="isCollapse && isLogin">
        <el-menu background-color="rgb(102, 102, 102)" text-color="#fff" active-text-color="yellow"
          :default-active="active">
          <el-menu-item index="/" @click="goto('Home')">
            <i class="el-icon-s-home"></i>
            <span slot="title">首页</span>
          </el-menu-item>
          <el-submenu index="/Lesson">
            <template slot="title">
              <i class="el-icon-date"></i>
              <span>课程</span>
            </template>
            <el-menu-item-group>
              <el-menu-item v-for="i in allCourses" :key="i.id" :index="coursesIndex(i.id)"
                @click="goto('CoursesManagement',i)">
                {{i.courseName}}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="/StudentsManagement" @click="goto('StudentsManagement')">
            <i class="el-icon-user-solid"></i>
            <span slot="title">用户与学生管理</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main :class="mainStyle">
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
  import {
    createNamespacedHelpers
  } from "vuex"
  const courses = createNamespacedHelpers('courses')
  const student = createNamespacedHelpers('student')
  const admin = createNamespacedHelpers('admin')

  export default {
    components: {},
    data() {
      return {
        isCollapse: true,
      }
    },
    computed: {
      ...courses.mapGetters(['allCourses']),
      ...admin.mapGetters(['isLogin', 'userInfo']),
      active() {
        let path = this.$route.path
        if (path == "Home") {
          return "/"
        } else {
          return path
        }
      },
      mainStyle() {
        if (this.isCollapse && this.isLogin) {
          return 'collapse'
        } else if (!this.isCollapse && this.isLogin) {
          return 'login'
        } else {
          return 'login bgcolor'
        }
      }
    },
    watch: {
      isLogin(v) {
        if (v) {
          this.getAllCourses()
          this.getAllUser()
        }
      }
    },
    methods: {
      ...student.mapActions(['getAllUser']),
      ...courses.mapActions(['getAllCourses']),
      ...admin.mapActions(['logout']),
      coursesIndex(id) {
        return `/CoursesManagement/${id}`
      },
      goto(path, data) {
        let isHome = path == 'Home'
        path = isHome ? '/' : path
        let currentRouteJudge = data ? `/${path}/${data.id}` != this.$route.path : isHome ?
          path != this.$route.path :
          `/${path}` != this.$route
          .path
        if (currentRouteJudge) {
          let option = {}
          isHome ? option.path = path : option.name = path
          data ? option.params = {
            name: data.id,
            data: data,
          } : null
          this.$router.push(option)
        }
      },
      backHome() {
        this.$router.push('/')
      },
      clickDropdown(item) {
        if (item == 'changePassword') {
          this.goto('PasswordManage')
        }
        if (item == 'logout') {
          this.logout().then(() => {
            this.$message({
              type: 'success',
              message: '注销成功'
            })
            this.$router.push('/Login')
          }).catch(() => {
            this.$message.error('注销失败')
          })
        }
      }
    },
    mounted() {
      if (this.isLogin) {
        this.getAllCourses()
        this.getAllUser()
      }
    },
  }
</script>

<style lang="stylus">
  .header {
    padding: 0;
    z-index: 1000;
    width: 100%;
  }

  .el-menu {
    border: none;
  }

  #app {
    height: 100%;
  }

  .el-aside {
    display: block;
    position: absolute;
    left: 0;
    top: 60px;
    bottom: 0;
  }

  .more {
    float: left;
    padding: 10px;
  }

  .logo {
    height: 60px;
    width: 60px;
  }

  .user {
    float: right;
    padding: 10px;
  }

  .el-main {
    left: 300px;
    top: 60px;
    height: calc(100% - 60px);
    width: 100%;
  }

  .collapse {
    position: absolute;
    width: calc(100% - 300px);
  }

  .login {
    position: absolute;
    left: 0;
  }

  .bgcolor {
    top: 0;
    height: 100%;
    background-image: linear-gradient(125deg,#F44336,#E91E63,#9C27B0,#3F51B5,#2196F3);
    background-size: 400%;
    font-family: "montserrat";
    animation: bganimation 15s infinite;
  }

  @keyframes bganimation{
    0%{
        background-position: 0% 50%;
    }
    50%{
        background-position: 100% 50%;
    }
    100%{
        background-position: 0% 50%;
    }
  }

  .el-dialog {
    margin: 0 auto !important;
    max-height: 90%;
    overflow: auto;
  }

  .pub_dialog {
    display: flex;
    align-items: Center;
    overflow: hidden;
  }

  .ql-container {
    height: 150px !important;
    padding: 8px !important;
  }

  .ql-editor {
    padding: 0 !important;
  }

  .ql-toolbar {
    padding: 0 !important;
  }
</style>