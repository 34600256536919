import Vue from "vue";
import VueRouter from "vue-router";
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }

Vue.use(VueRouter);

const routes = [
  {
    path: "/Login",
    name: "Login",
    component: () => import("@/components/Login")
  },
  {
    path: "/",
    name: "Home",
    component: () => import("@/components/Home")
  },
  {
    path: "/PasswordManage",
    name: "PasswordManage",
    component: () => import("@/components/PasswordManage")
  },
  {
    path: "/StudentsManagement",
    name: "StudentsManagement",
    component: () => import("@/components/StudentsManagement")
  },
  {
    path: "/CoursesManagement/:name",
    name: "CoursesManagement",
    component: () => import("@/components/CoursesManagement")
  },
  {
    path: "/CoursesManagement/:name/LessonsModel",
    name: "LessonsModel",
    component: () => import("@/components/LessonsModel")
  },
];

const router = new VueRouter({
  routes
});

export default router;