import axios from "axios"

const setAllStudents = "setAllStudents"
const setAddStudent = "setAddStudent"
const setDeleteStudent = "setDeleteStudent"

const setAllUser = "setAllUser"
const setAddUser = "setAddUser"
const setDeleteUser = "setDeleteUser"

const URL_GetUser = "/api/admin/wechatUser/GetList"
const URL_AddUser = "/api/admin/wechatUser/Add"
const URL_DeleteUser = "/api/admin/wechatUser/Delete"

const URL_GetStudents = "/api/admin/student/GetList"
const URL_AddStudent = "/api/admin/student/Add"
const URL_DeleteStudent = "/api/admin/student/Delete"

function initialState() {
  return {
    allUser: [],
    allStudents: []
  }
}

export default {
  namespaced: true,

  state: initialState,

  actions: {
    async getAllUser({
      commit
    }) {
      return axios.get(URL_GetUser).then(res => {
        commit(setAllUser, res.data)
      }).catch((error) => {
        console.log(error)
        throw error
      })
    },
    async addUser({
      commit
    }, data) {
      return axios.post(URL_AddUser, data).then(res => {
        commit(setAddUser, res.data)
      }).catch((error) => {
        console.log(error)
        throw error
      })
    },
    async deleteUser({
      commit
    }, data) {
      return axios.delete(URL_DeleteUser + '/' + data).then(() => {
        commit(setDeleteUser, data)
      }).catch((error) => {
        console.log(error)
        throw error
      })
    },
    async getAllStudents({
      commit
    }) {
      return axios.get(URL_GetStudents).then(res => {
        commit(setAllStudents, res.data)
      }).catch((error) => {
        console.log(error)
        throw error
      })
    },
    async addStudent({
      commit
    }, data) {
      return axios.post(URL_AddStudent, data).then(res => {
        commit(setAddStudent, res.data)
      }).catch((error) => {
        console.log(error)
        throw error
      })
    },
    async deleteStudent({
      commit
    }, data) {
      return axios.delete(URL_DeleteStudent + '/' + data).then(() => {
        commit(setDeleteStudent, data)
      }).catch((error) => {
        console.log(error)
        throw error
      })
    }
  },

  mutations: {
    [setAllUser](state, data) {
      state.allUser = data
    },
    [setAddUser](state, data) {
      state.allUser.push(data)
    },
    [setDeleteUser](state, data) {
      let index = state.allUser.findIndex(x => x.id == data)
      if (index != -1) {
        state.allUser.splice(index, 1)
      }
    },

    [setAllStudents](state, data) {
      state.allStudents = data
    },
    [setAddStudent](state, data) {
      state.allStudents.push(data)
    },
    [setDeleteStudent](state, data) {
      let index = state.allStudents.findIndex(x => x.id == data)
      if (index != -1) {
        state.allStudents.splice(index, 1)
      }
    }
  },

  getters: {
    allUser: state => state.allUser,
    allStudents: state => state.allStudents
  }
}