import axios from "axios"
const URL_SendLessonFeedbackMessage = "/api/admin/subscribe/SendLessonFeedbackMessage"
const URL_SendCourseUpdateMessage = "/api/admin/subscribe/SendCourseUpdateMessage"
const URL_UploadFile="/api/admin/upload/create"

function initialState() {
  return {
    
  }
}

export default {
  namespaced: true,

  state: initialState,

  actions: {
    async sendLessonFeedbackMessage({ commit }, data) {
      let { lessonId, studentId } = data
      return axios.get(URL_SendLessonFeedbackMessage + '?lessonId=' + lessonId + '&&studentId=' + studentId).then(res => {
          console.log(res)
      }).catch(error => {
          console.log(error)
          throw error
      })
    },
    async sendCourseUpdateMessage({ commit }, data) {
      return axios.get(URL_SendCourseUpdateMessage + '?courseId=' + data).then(res => {
        console.log(res)
    }).catch(error => {
        console.log(error)
        throw error
    })
    },
    async uploadImage({ commit }, data) {
      console.log(data)
      var formData = new FormData();
      formData.append('image', data.raw, data.name);
      return axios.post(URL_UploadFile, formData, {
        headers: {
        "Content-type": "multipart/form-data"
        }   
      }).then(res => {
        return res
      }).catch(error => {
        throw error
      }) 
    }
  },

  mutations: {
  },

  getters: {
    
  }
}