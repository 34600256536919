import axios from "axios"

const setAllCourses = "setAllCourses"
const setAddCourses = "setAddCourses"
const setDeleteCourses = "setDeleteCourses"
const setEditCourses = "setEditCourses"
const setCurrentLessons = "setCurrentLessons"
const setAddLessons = "setAddLessons"
const setDeleteLessons = "setDeleteLessons"
const setEditLessons = "setEditLessons"
const setCurrentStudents = "setCurrentStudents"
const setAddStudents = "setAddStudents"
const setDeleteStudents = "setDeleteStudents"

const URL_GetAllCourses = "/api/admin/course/getlist"
const URL_AddCourses = "/api/admin/course/add"
const URL_EditCourse = "/api/admin/course/update"
const URL_DeleteCourses = "/api/admin/course/delete"
const URL_GetCourseDetailById = "/api/admin/course/getdetail"
const URL_AddLessons = "/api/admin/lesson/add"
const URL_DeleteLessons = "/api/admin/lesson/delete"
const URL_EditLessons = "api/admin/lesson/update"
const URL_PublishLessons = "api/admin/lesson/publish"
const URL_AddStudents = "api/admin/enrollment/add"
const URL_DeleteStudents = "api/admin/enrollment/delete"

function initialState() {
  return {
    allCourses: [],
    currentLessons: [],
    currentStudents: [],
  }
}

export default {
  namespaced: true,

  state: initialState,

  actions: {
    async getAllCourses({
      commit
    }) {
      return axios.get(URL_GetAllCourses).then(res => {
        commit(setAllCourses, res.data)
      }).catch((error) => {
        console.log(error)
        throw error
      })
    },
    async addCourses({
      commit
    }, data) {
      return axios.post(URL_AddCourses, data).then(res => {
        commit(setAddCourses, res.data)
      }).catch((error) => {
        console.log(error)
        throw error
      })
    },
    async deleteCourses({
      commit
    }, data) {
      return axios.delete(URL_DeleteCourses + '/' + data).then(() => {
        commit(setDeleteCourses, data)
      }).catch((error) => {
        console.log(error)
        throw error
      })
    },
    async editCourses({
      commit
    }, data) {
      return axios.put(URL_EditCourse, data).then((res) => {
        commit(setEditCourses, res.data)
      }).catch((error) => {
        console.log(error)
        throw error
      })
    },
    async getCourseDetailById({
      commit
    }, data) {
      return axios.get(URL_GetCourseDetailById + '/' + data).then((res) => {
        commit(setCurrentStudents, res.data.students)
        commit(setCurrentLessons, res.data.lessons)
      }).catch((error) => {
        console.log(error)
        throw error
      })
    },
    async addLessons({
      commit
    }, data) {
      return axios.post(URL_AddLessons, data).then(res => {
        commit(setAddLessons, res.data)
      }).catch((error) => {
        console.log(error)
        throw error
      })
    },
    async deleteLessons({
      commit
    }, data) {
      return axios.delete(URL_DeleteLessons + '/' + data).then(() => {
        commit(setDeleteLessons, data)
      }).catch((error) => {
        console.log(error)
        throw error
      })
    },
    async editLessons({
      commit
    }, data) {
      return axios.put(URL_EditLessons, data).then((res) => {
        commit(setEditLessons, res.data)
      }).catch((error) => {
        console.log(error)
        throw error
      })
    },
    async publishLessons({
      commit
    }, data) {
      return axios.put(URL_PublishLessons, data).then((res) => {
        commit(setEditLessons, res.data)
      }).catch((error) => {
        console.log(error)
        throw error
      })
    },
    async addStudents({
      commit, dispatch, state, rootState
    }, data) {
      return axios.post(URL_AddStudents, data).then(()=> {
        let students = rootState.student.allStudents.filter(x => data.studentIds.includes(x.id))
        commit(setAddStudents, students)
      }).catch((error) => {
        console.log(error)
        throw error
      })
    },
    async deleteStudents({
      commit
    }, data) {
      return axios.delete(URL_DeleteStudents, {data:data}).then(() => {
        commit(setDeleteStudents, data.studentIds)
      }).catch((error) => {
        console.log(error)
        throw error
      })
    },
  },

  mutations: {
    [setAllCourses](state, data) {
      state.allCourses = data
    },
    [setAddCourses](state, data) {
      state.allCourses.push(data)
    },
    [setDeleteCourses](state, data) {
      let index = state.allCourses.findIndex(x => x.id == data)
      if (index != -1) {
        state.allCourses.splice(index, 1)
      }
    },
    [setEditCourses](state, data) {
      let index = state.allCourses.findIndex(x => x.id == data.id)
      if (index != -1) {
        state.allCourses.splice(index, 1, data)
      }
    },
    [setCurrentLessons](state, data) {
      state.currentLessons = data
    },
    [setAddLessons](state, data) {
      state.currentLessons.push(data)
    },
    [setDeleteLessons](state, data) {
      let index = state.currentLessons.findIndex(x => x.id == data)
      if (index != -1) {
        state.currentLessons.splice(index, 1)
      }
    },
    [setEditLessons](state, data) {
      let index = state.currentLessons.findIndex(x => x.id == data.id)
      if (index != -1) {
        state.currentLessons.splice(index, 1, data)
      }
    },
    [setCurrentStudents](state, data) {
      state.currentStudents = data
    },
    [setAddStudents](state, data) {
      state.currentStudents.push(...data)
    },
    [setDeleteStudents](state, data) {
      data.forEach(id => {
        let index = state.currentStudents.findIndex(x => x.id == id)
        if (index != -1) {
          state.currentStudents.splice(index, 1)
        }
      });
    },
  },

  getters: {
    allCourses: state => state.allCourses,
    currentLessons: state => state.currentLessons,
    currentStudents: state => state.currentStudents,
  }
};