import Vue from "vue"
import Vuex from "vuex"
import student from "./student"
import courses from "./courses"
import admin from "./admin"
import feedback from "./feedback"
import subscribeMessage from "./subscribeMessage"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    student,
    courses,
    admin,
    feedback,
    subscribeMessage
  }
})