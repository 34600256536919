import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import VueRouter from 'vue-router';
import router from "./router";
import store from "./store";
import axios from 'axios'
Vue.use(VueRouter);
Vue.use(ElementUI);
Vue.config.productionTip = false;
axios.interceptors.request.use(config => {
  if (config.url != 'adminToken') {
    let token = window.localStorage.getItem('_access_token')
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
      return config
    }
  }
  return config
})

let logintime = parseInt(window.localStorage.getItem('_logintime'))
let expires_in = parseInt(window.localStorage.getItem('_expires_in'))
let time = new Date().getTime()
if ((logintime + expires_in * 1000) > time) {
  store.state.admin.isLogin = true
  store.state.admin.userInfo = JSON.parse(window.localStorage.getItem('_userInfo'))
}
router.beforeEach((to, from, next) => {
  if (to.path === '/Login') {
    next()
  } else {
    if (!store.state.admin.isLogin) {
      next('Login')
    } else {
      next()
    }
  }
})
new Vue({
  router,
  store,
  el: "#app",
  render: h => h(App)
}).$mount("#app");